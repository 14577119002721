import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeroSection7 from "../assets/googleads/HeroSection7";
import AdsContent from "..//assets/googleads/AdsContent";
import BottomCTA from "..//assets/home/BottomCTA";

const DigitalMarketingAgencyinSingapore = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Google Ads | Maximize Your ROI with RedSprout Digital</title>
          <meta
            name="description"
            content="Boost your business with expert Google Ads management by RedSprout Digital. Increase traffic, generate leads, maximize your ROI with our tailored strategies."
          />
          <meta
            name="keywords"
            content="google ads, google advertising, advertise on google"
          />
          <meta property="og:title" content="Google Ads | Maximize Your ROI with RedSprout Digital" />
          <meta property="og:description" content="Boost your business with expert Google Ads management by RedSprout Digital. Increase traffic, generate leads, maximize your ROI with our tailored strategies." />
          <meta property="og:image" content="https://redsproutdigital.com/Images/logo.webp?v=1" />
          <meta property="og:url" content="https://redsproutdigital.com/google-ads/" />
          <link rel="canonical" href="https://redsproutdigital.com/google-ads/" />
        </Helmet>
        {/* Hero Section */}
        <HeroSection7 />
        <AdsContent />
        <BottomCTA />



      </div>
    </HelmetProvider>
  );
};

export default DigitalMarketingAgencyinSingapore;
