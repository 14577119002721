import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeroSectionCanada from "../assets/digitalmarketingagency/HeroSectionCanada";
import ContentCanada from "..//assets/digitalmarketingagency/ContentCanada";
import BottomCTA from "..//assets/home/BottomCTA";

const DigitalMarketingAgencyinCanada = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Top Digital Marketing Agency in Canada | RedSprout Digital</title>
          <meta
            name="description"
            content="Looking for a top digital marketing agency in Canada? RedSprout Digital offers expert SEO, web development, and paid ads services to grow your business fast."
          />
          <meta
            name="keywords"
            content="digital marketing agency in canada, digital marketing services in canada, paid ads management services in canada, lead generation services in canada, best seo agency canada"
          />
          <meta property="og:title" content="Top Digital Marketing Agency in Canada | RedSprout Digital" />
          <meta property="og:description" content="Looking for a top digital marketing agency in Canada? RedSprout Digital offers expert SEO, web development, and paid ads services to grow your business fast." />
          <meta property="og:image" content="https://redsproutdigital.com/Images/logo.webp?v=1" />
          <meta property="og:url" content="https://redsproutdigital.com/digital-marketing-agency-in-canada/" />
          <link rel="canonical" href="https://redsproutdigital.com/digital-marketing-agency-in-canada/" />
        </Helmet>
        {/* Hero Section */}
        <HeroSectionCanada />
        <ContentCanada />
        <BottomCTA />



      </div>
    </HelmetProvider>
  );
};

export default DigitalMarketingAgencyinCanada;
