import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { db } from '../firebase/Firebase';  // Ensure your Firebase is properly set up
import { collection, getDocs } from 'firebase/firestore';
import parse from 'html-react-parser';
import { format } from 'date-fns';
import HeroSectionBlog from "../assets/blog/HeroSectionBlog";

const BlogList = () => {
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState('Latest Updates');
  const [visiblePosts, setVisiblePosts] = useState(15);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        // Fetch from 'blogs' collection instead of 'posts'
        const querySnapshot = await getDocs(collection(db, 'posts'));  
        const postsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Extract categories from posts, handle undefined categories
        const uniqueCategories = [...new Set(postsData.map(post => post.category || 'Uncategorized'))];  // Ensure 'category' matches your field
        setCategories(uniqueCategories);

        // Sort posts by postDate in descending order (most recent first)
        const sortedPosts = postsData.sort((a, b) => b.postDate.seconds - a.postDate.seconds);
        setPosts(sortedPosts);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };

    fetchPosts();
  }, []);

  const showMorePosts = () => {
    setVisiblePosts(prevVisiblePosts => prevVisiblePosts + 15);
  };

  const calculateReadingTime = (text) => {
    const wordsPerMinute = 200;
    const textLength = text.split(/\s+/).length;
    const readingTime = Math.ceil(textLength / wordsPerMinute);
    return readingTime;
  };

  const formatDate = (date) => {
    return format(new Date(date.seconds * 1000), 'MMMM d, yyyy');
  };

  return (
    <HelmetProvider>
      <div className="bg-gradient-to-tl from-red-100 via-white to-red-100 min-h-screen">
        <Helmet>
          <title>RedSprout Digital Blog | Insights on Digital Marketing, SEO, PPC</title>
          <meta
            name="description"
            content="Explore the RedSprout Digital Blog for expert insights on digital marketing, SEO, PPC, web development, and strategies to boost your business growth online."
          />
          <meta
            name="keywords"
            content="blog, digital marketing insights, digital marketing blog, lead generation blog"
          />
          <meta property="og:title" content="RedSprout Digital Blog | Insights on Digital Marketing, SEO, PPC" />
          <meta property="og:description" content="Explore the RedSprout Digital Blog for expert insights on digital marketing, SEO, PPC, web development, and strategies to boost your business growth online." />
          <meta property="og:image" content="#" />
          <meta property="og:url" content="https://redsproutdigital.com/blog/" />
          <link rel="canonical" href="https://redsproutdigital.com/blog/" />
        </Helmet>
        <HeroSectionBlog />
        <div className="container mx-auto p-4">
          {/* Tabs Section */}
          <div className="flex space-x-4 mb-16 overflow-x-auto">
            <button
              onClick={() => setActiveTab('Latest Updates')}
              className={`px-4 py-2 rounded-full text-white ${activeTab === 'Latest Updates' ? 'bg-red-600' : 'bg-black'
                }`}
            >
              Latest Updates
            </button>
            {categories.map(category => (
              <button
                key={category}
                onClick={() => setActiveTab(category)}
                className={`px-4 py-2 rounded-full text-white ${activeTab === category ? 'bg-red-600' : 'bg-black'
                  }`}
              >
                {category}
              </button>
            ))}
          </div>

          {/* Blog Posts Section */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {posts.length > 0 ? (
              posts
                .filter(post => post.category === activeTab || activeTab === 'Latest Updates')
                .slice(0, visiblePosts)
                .map(post => (
                  <div key={post.id} className="bg-white shadow-md rounded-lg overflow-hidden">
                    {post.featuredImage && (
                      <img
                        src={post.featuredImage}
                        alt={post.title || 'No Title'}
                        className="w-full h-48 object-cover"
                      />
                    )}
                    <div className="p-4">
                      <h2 className="text-xl font-bold mb-2">{post.title || 'Untitled Post'}</h2>
                      <h3 className="text-sm font-semibold text-gray-500 mb-2">
                        {post.category || 'Uncategorized'}
                      </h3>
                      <p className="text-gray-700 mb-4">
                        {post.body ? parse(post.body.split(' ').slice(0, 21).join(' ') + '...') : 'No content available...'}
                      </p>
                      <div className="text-gray-500 text-sm flex items-center">
                        <span>{formatDate(post.postDate)}</span>
                        <span className="mx-2">•</span>
                        <span className="ml-2">{calculateReadingTime(post.body)} min read</span>
                      </div>
                      <a
                        href={post.permalink}
                        className="bg-red-600 text-white px-6 py-2 rounded-md hover:bg-red-700 transition duration-300 inline-block mt-4"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                ))
            ) : (
              <p>No blog posts available.</p>
            )}
          </div>

          {/* Show More Button */}
          {visiblePosts < posts.length && (
            <div className="flex justify-center mt-8">
              <button
                onClick={showMorePosts}
                className="bg-red-600 text-white px-6 py-3 rounded-md hover:bg-red-700 transition duration-300"
              >
                Show More
              </button>
            </div>
          )}
        </div>
      </div>
    </HelmetProvider>
  );
};

export default BlogList;
