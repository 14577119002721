import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeroSectionUK from "../assets/digitalmarketingagency/HeroSectionUK";
import ContentUK from "..//assets/digitalmarketingagency/ContentUK";
import BottomCTA from "..//assets/home/BottomCTA";

const DigitalMarketingAgencyinUK = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Top Digital Marketing Agency in UK | RedSprout Digital</title>
          <meta
            name="description"
            content="Looking for a top digital marketing agency in UK? RedSprout Digital offers expert SEO, web development, and paid ads services to grow your business fast."
          />
          <meta
            name="keywords"
            content="digital marketing agency in uk, best seo services uk, digital marketing london, web developers in uk, ecommerce web design company uk"
          />
          <meta property="og:title" content="Top Digital Marketing Agency in UK | RedSprout Digital" />
          <meta property="og:description" content="Looking for a top digital marketing agency in UK? RedSprout Digital offers expert SEO, web development, and paid ads services to grow your business fast." />
          <meta property="og:image" content="https://redsproutdigital.com/Images/logo.webp?v=1" />
          <meta property="og:url" content="https://redsproutdigital.com/digital-marketing-agency-in-uk/" />
          <link rel="canonical" href="https://redsproutdigital.com/digital-marketing-agency-in-uk/" />
        </Helmet>
        {/* Hero Section */}
        <HeroSectionUK />
        <ContentUK />
        <BottomCTA />



      </div>
    </HelmetProvider>
  );
};

export default DigitalMarketingAgencyinUK;
