import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeroSectionSingapore from "../assets/digitalmarketingagency/HeroSectionSingapore";
import ContentSingapore from "..//assets/digitalmarketingagency/ContentSingapore";
import BottomCTA from "..//assets/home/BottomCTA";

const DigitalMarketingAgencyinCanada = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Leading Digital Marketing Agency in Singapore | RedSprout Digital</title>
          <meta
            name="description"
            content="Looking for a top digital marketing agency in Singapore? RedSprout Digital offers expert SEO, web development, and paid ads services to grow your business fast."
          />
          <meta
            name="keywords"
            content="digital marketing agency in singapore, singapore seo firm, digital marketing sg, singapore social media marketing agency"
          />
          <meta property="og:title" content="Leading Digital Marketing Agency in Singapore | RedSprout Digital" />
          <meta property="og:description" content="Looking for a top digital marketing agency in Singapore? RedSprout Digital offers expert SEO, web development, and paid ads services to grow your business fast." />
          <meta property="og:image" content="https://redsproutdigital.com/Images/logo.webp?v=1" />
          <meta property="og:url" content="https://redsproutdigital.com/digital-marketing-agency-in-singapore/" />
          <link rel="canonical" href="https://redsproutdigital.com/digital-marketing-agency-in-singapore/" />
        </Helmet>
        {/* Hero Section */}
        <HeroSectionSingapore />
        <ContentSingapore />
        <BottomCTA />



      </div>
    </HelmetProvider>
  );
};

export default DigitalMarketingAgencyinCanada;
