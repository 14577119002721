import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { db } from '../firebase/Firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { format } from 'date-fns';
import parse from 'html-react-parser';
import axios from 'axios'; // Add axios for form submission

const BlogPostPage = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    countryCode: '+1', // Default country code to +1
    phone: '',
    service: 'Digital Marketing', // Default service
    message: '',
  });
  const [formStatus, setFormStatus] = useState({ success: false, error: null });
  const [isSubmitting, setIsSubmitting] = useState(false); // Add state to track form submission

  useEffect(() => {
    const fetchPost = async () => {
      try {
        setLoading(true);
        console.log(`Fetching post with permalink: ${id}`);

        // Fetch blog post from the 'blogs' collection
        const postsRef = collection(db, 'posts'); // Updated collection name to 'blogs'
        const q = query(postsRef, where('permalink', '==', `/blog/${id}`));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const docSnap = querySnapshot.docs[0];
          const postData = docSnap.data();
          setPost(postData);

          await fetchRelatedPosts(postData.categories || [], postData.tags || []);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching the blog post:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchRelatedPosts = async (categories, tags) => {
      try {
        console.log('Fetching related posts...');
        const postsRef = collection(db, 'blogs'); // Updated collection name to 'blogs'
        const q = query(
          postsRef,
          where('categories', 'array-contains-any', categories),
          where('tags', 'array-contains-any', tags)
        );
        const querySnapshot = await getDocs(q);
        const relatedPostsData = querySnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(post => post.permalink !== `/blog/${id}`);

        console.log('Related posts:', relatedPostsData);
        setRelatedPosts(relatedPostsData);
      } catch (error) {
        console.error('Error fetching related posts:', error);
      }
    };

    fetchPost();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the input is the phone number, ensure only digits are allowed
    if (name === 'phone') {
      if (/^\d*$/.test(value)) {  // Only update the state if the value is numeric
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set submitting state to true
    try {
      const dataToSend = {
        ...formData,
        subject: `Enquiry from Blog: ${post.title}`,
      };
      await axios.post('https://5gno3n9tad.execute-api.us-east-1.amazonaws.com/dev/send-email', dataToSend);
      setFormStatus({ success: true, error: null });
      setFormData({ name: '', email: '', phone: '', service: 'Digital Marketing', message: '' });
      alert('Inquiry submitted successfully!');
    } catch (error) {
      console.error('Error submitting form:', error);
      setFormStatus({ success: false, error: 'Failed to submit inquiry' });
      alert('Failed to submit inquiry');
    } finally {
      setIsSubmitting(false); // Set submitting state to false after completion
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!post) {
    return <p>No blog post found.</p>;
  }

  const calculateReadingTime = (text) => {
    const wordsPerMinute = 200;
    const textLength = text.split(/\s+/).length;
    const readingTime = Math.ceil(textLength / wordsPerMinute);
    return readingTime;
  };

  const formatDate = (date) => {
    return format(new Date(date.seconds * 1000), 'MMMM d, yyyy');
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>{post.metaTitle || post.title}</title>
        <meta name="description" content={post.metaDescription || post.subtitle} />
        <meta name="keywords" content={post.keywords ? post.keywords.join(', ') : ''} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={post.metaTitle || post.title} />
        <meta property="og:description" content={post.metaDescription || post.subtitle} />
        <meta property="og:image" content={post.featuredImage || '#'} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <div className="container mx-auto p-4 grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Left Column: Blog Content */}
        <div className="lg:col-span-2">
          {/* Breadcrumb */}
          <div className="text-gray-500 mb-4">
            <Link to="/blog" className="hover:text-gray-900">Blogs</Link> &gt; <span className="text-black font-semibold">Blog Post</span>
          </div>

          {/* Featured Image */}
          {post.featuredImage && (
            <img
              src={post.featuredImage}
              alt={post.title}
              className="mb-4 rounded-lg max-h-64 object-cover w-full sm:max-h-100"
            />
          )}

          {/* Title */}
          <h1 className="text-4xl font-bold mb-4">{post.title}</h1>

          {/* Category */}
          <div className="mb-2">
            {post.categories && post.categories.map(category => (
              <span key={category} className="inline-block bg-gray-200 text-gray-700 px-3 py-1 rounded-full text-sm mr-2">
                {category}
              </span>
            ))}
          </div>

          {/* Date and Reading Time */}
          <div className="text-gray-500 mb-8 flex items-center">
            <span>{formatDate(post.postDate)}</span>
            <span className="mx-2">•</span>
            <span>{calculateReadingTime(post.body)} min read</span>
          </div>

          {/* Content */}
          <div className="prose max-w-none mb-8 text-justify">{post.body && parse(post.body)}</div>

          {/* Related Articles */}
          {relatedPosts.length > 0 && (
            <div className="related-articles">
              <h2 className="text-2xl font-bold mb-4">Related Articles</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {relatedPosts.map(relatedPost => (
                  <div key={relatedPost.id} className="bg-white shadow-md rounded-lg overflow-hidden">
                    {relatedPost.featuredImage && (
                      <img
                        src={relatedPost.featuredImage}
                        alt={relatedPost.title || 'No Title'}
                        className="w-full h-32 sm:h-48 object-cover"
                      />
                    )}
                    <div className="p-4">
                      <h3 className="text-xl font-bold mb-2">{relatedPost.title || 'Untitled Post'}</h3>
                      <p className="text-gray-700 mb-4">
                        {relatedPost.body ? parse(relatedPost.body.split(' ').slice(0, 15).join(' ') + '...') : 'No content available...'}
                      </p>
                      <a href={relatedPost.permalink} className="text-red-600 hover:underline mt-4 block">
                        Read more
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

         {/* Right Column: Sticky Inquiry Form */}
         <div className="lg:col-span-1">
          <div className="sticky top-20">
            <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
            <form className="bg-black text-white shadow-md rounded-lg p-4" onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="sr-only" htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  aria-label="Name"
                  className="w-full px-3 py-2 border rounded text-black"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-4">
                <label className="sr-only" htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  aria-label="Email"
                  className="w-full px-3 py-2 border rounded text-black"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="sr-only" htmlFor="phone">Mobile Number</label>
                <div className="flex space-x-2">
                  <select
                    name="countryCode"
                    value={formData.countryCode}
                    onChange={handleChange}
                    className="w-1/2 px-4 py-2 border border-gray-300 rounded-lg text-black"
                  >
                    {/* List of country options */}
                    <option value="+93">+93 (Afghanistan)</option>
                    <option value="+355">+355 (Albania)</option>
                    <option value="+213">+213 (Algeria)</option>
                    <option value="+1-684">+1-684 (American Samoa)</option>
                    <option value="+376">+376 (Andorra)</option>
                    <option value="+244">+244 (Angola)</option>
                    <option value="+1-264">+1-264 (Anguilla)</option>
                    <option value="+1-268">+1-268 (Antigua and Barbuda)</option>
                    <option value="+54">+54 (Argentina)</option>
                    <option value="+374">+374 (Armenia)</option>
                    <option value="+297">+297 (Aruba)</option>
                    <option value="+61">+61 (Australia)</option>
                    <option value="+43">+43 (Austria)</option>
                    <option value="+994">+994 (Azerbaijan)</option>
                    <option value="+1-242">+1-242 (Bahamas)</option>
                    <option value="+973">+973 (Bahrain)</option>
                    <option value="+880">+880 (Bangladesh)</option>
                    <option value="+1-246">+1-246 (Barbados)</option>
                    <option value="+375">+375 (Belarus)</option>
                    <option value="+32">+32 (Belgium)</option>
                    <option value="+501">+501 (Belize)</option>
                    <option value="+229">+229 (Benin)</option>
                    <option value="+1-441">+1-441 (Bermuda)</option>
                    <option value="+975">+975 (Bhutan)</option>
                    <option value="+591">+591 (Bolivia)</option>
                    <option value="+387">+387 (Bosnia and Herzegovina)</option>
                    <option value="+267">+267 (Botswana)</option>
                    <option value="+55">+55 (Brazil)</option>
                    <option value="+246">+246 (British Indian Ocean Territory)</option>
                    <option value="+1-284">+1-284 (British Virgin Islands)</option>
                    <option value="+673">+673 (Brunei)</option>
                    <option value="+359">+359 (Bulgaria)</option>
                    <option value="+226">+226 (Burkina Faso)</option>
                    <option value="+257">+257 (Burundi)</option>
                    <option value="+855">+855 (Cambodia)</option>
                    <option value="+237">+237 (Cameroon)</option>
                    <option value="+1">+1 (Canada)</option>
                    <option value="+238">+238 (Cape Verde)</option>
                    <option value="+1-345">+1-345 (Cayman Islands)</option>
                    <option value="+236">+236 (Central African Republic)</option>
                    <option value="+235">+235 (Chad)</option>
                    <option value="+56">+56 (Chile)</option>
                    <option value="+86">+86 (China)</option>
                    <option value="+57">+57 (Colombia)</option>
                    <option value="+269">+269 (Comoros)</option>
                    <option value="+242">+242 (Congo - Brazzaville)</option>
                    <option value="+243">+243 (Congo - Kinshasa)</option>
                    <option value="+682">+682 (Cook Islands)</option>
                    <option value="+506">+506 (Costa Rica)</option>
                    <option value="+225">+225 (Cote d'Ivoire)</option>
                    <option value="+385">+385 (Croatia)</option>
                    <option value="+53">+53 (Cuba)</option>
                    <option value="+357">+357 (Cyprus)</option>
                    <option value="+420">+420 (Czech Republic)</option>
                    <option value="+45">+45 (Denmark)</option>
                    <option value="+253">+253 (Djibouti)</option>
                    <option value="+1-767">+1-767 (Dominica)</option>
                    <option value="+1-809">+1-809 (Dominican Republic)</option>
                    <option value="+593">+593 (Ecuador)</option>
                    <option value="+20">+20 (Egypt)</option>
                    <option value="+503">+503 (El Salvador)</option>
                    <option value="+240">+240 (Equatorial Guinea)</option>
                    <option value="+291">+291 (Eritrea)</option>
                    <option value="+372">+372 (Estonia)</option>
                    <option value="+251">+251 (Ethiopia)</option>
                    <option value="+500">+500 (Falkland Islands)</option>
                    <option value="+298">+298 (Faroe Islands)</option>
                    <option value="+679">+679 (Fiji)</option>
                    <option value="+358">+358 (Finland)</option>
                    <option value="+33">+33 (France)</option>
                    <option value="+594">+594 (French Guiana)</option>
                    <option value="+689">+689 (French Polynesia)</option>
                    <option value="+241">+241 (Gabon)</option>
                    <option value="+220">+220 (Gambia)</option>
                    <option value="+995">+995 (Georgia)</option>
                    <option value="+49">+49 (Germany)</option>
                    <option value="+233">+233 (Ghana)</option>
                    <option value="+350">+350 (Gibraltar)</option>
                    <option value="+30">+30 (Greece)</option>
                    <option value="+299">+299 (Greenland)</option>
                    <option value="+1-473">+1-473 (Grenada)</option>
                    <option value="+590">+590 (Guadeloupe)</option>
                    <option value="+1-671">+1-671 (Guam)</option>
                    <option value="+502">+502 (Guatemala)</option>
                    <option value="+44-1481">+44-1481 (Guernsey)</option>
                    <option value="+224">+224 (Guinea)</option>
                    <option value="+245">+245 (Guinea-Bissau)</option>
                    <option value="+592">+592 (Guyana)</option>
                    <option value="+509">+509 (Haiti)</option>
                    <option value="+504">+504 (Honduras)</option>
                    <option value="+852">+852 (Hong Kong)</option>
                    <option value="+36">+36 (Hungary)</option>
                    <option value="+354">+354 (Iceland)</option>
                    <option value="+91">+91 (India)</option>
                    <option value="+62">+62 (Indonesia)</option>
                    <option value="+98">+98 (Iran)</option>
                    <option value="+964">+964 (Iraq)</option>
                    <option value="+353">+353 (Ireland)</option>
                    <option value="+44-1624">+44-1624 (Isle of Man)</option>
                    <option value="+972">+972 (Israel)</option>
                    <option value="+39">+39 (Italy)</option>
                    <option value="+1-876">+1-876 (Jamaica)</option>
                    <option value="+81">+81 (Japan)</option>
                    <option value="+44-1534">+44-1534 (Jersey)</option>
                    <option value="+962">+962 (Jordan)</option>
                    <option value="+7">+7 (Kazakhstan)</option>
                    <option value="+254">+254 (Kenya)</option>
                    <option value="+686">+686 (Kiribati)</option>
                    <option value="+850">+850 (North Korea)</option>
                    <option value="+82">+82 (South Korea)</option>
                    <option value="+965">+965 (Kuwait)</option>
                    <option value="+996">+996 (Kyrgyzstan)</option>
                    <option value="+856">+856 (Laos)</option>
                    <option value="+371">+371 (Latvia)</option>
                    <option value="+961">+961 (Lebanon)</option>
                    <option value="+266">+266 (Lesotho)</option>
                    <option value="+231">+231 (Liberia)</option>
                    <option value="+218">+218 (Libya)</option>
                    <option value="+423">+423 (Liechtenstein)</option>
                    <option value="+370">+370 (Lithuania)</option>
                    <option value="+352">+352 (Luxembourg)</option>
                    <option value="+853">+853 (Macau)</option>
                    <option value="+389">+389 (Macedonia)</option>
                    <option value="+261">+261 (Madagascar)</option>
                    <option value="+265">+265 (Malawi)</option>
                    <option value="+60">+60 (Malaysia)</option>
                    <option value="+960">+960 (Maldives)</option>
                    <option value="+223">+223 (Mali)</option>
                    <option value="+356">+356 (Malta)</option>
                    <option value="+692">+692 (Marshall Islands)</option>
                    <option value="+596">+596 (Martinique)</option>
                    <option value="+222">+222 (Mauritania)</option>
                    <option value="+230">+230 (Mauritius)</option>
                    <option value="+262">+262 (Mayotte)</option>
                    <option value="+52">+52 (Mexico)</option>
                    <option value="+691">+691 (Micronesia)</option>
                    <option value="+373">+373 (Moldova)</option>
                    <option value="+377">+377 (Monaco)</option>
                    <option value="+976">+976 (Mongolia)</option>
                    <option value="+382">+382 (Montenegro)</option>
                    <option value="+1-664">+1-664 (Montserrat)</option>
                    <option value="+212">+212 (Morocco)</option>
                    <option value="+258">+258 (Mozambique)</option>
                    <option value="+95">+95 (Myanmar)</option>
                    <option value="+264">+264 (Namibia)</option>
                    <option value="+674">+674 (Nauru)</option>
                    <option value="+977">+977 (Nepal)</option>
                    <option value="+31">+31 (Netherlands)</option>
                    <option value="+687">+687 (New Caledonia)</option>
                    <option value="+64">+64 (New Zealand)</option>
                    <option value="+505">+505 (Nicaragua)</option>
                    <option value="+227">+227 (Niger)</option>
                    <option value="+234">+234 (Nigeria)</option>
                    <option value="+683">+683 (Niue)</option>
                    <option value="+672">+672 (Norfolk Island)</option>
                    <option value="+1-670">+1-670 (Northern Mariana Islands)</option>
                    <option value="+47">+47 (Norway)</option>
                    <option value="+968">+968 (Oman)</option>
                    <option value="+92">+92 (Pakistan)</option>
                    <option value="+680">+680 (Palau)</option>
                    <option value="+970">+970 (Palestinian Territory)</option>
                    <option value="+507">+507 (Panama)</option>
                    <option value="+675">+675 (Papua New Guinea)</option>
                    <option value="+595">+595 (Paraguay)</option>
                    <option value="+51">+51 (Peru)</option>
                    <option value="+63">+63 (Philippines)</option>
                    <option value="+48">+48 (Poland)</option>
                    <option value="+351">+351 (Portugal)</option>
                    <option value="+1-787">+1-787 (Puerto Rico)</option>
                    <option value="+974">+974 (Qatar)</option>
                    <option value="+262">+262 (Reunion)</option>
                    <option value="+40">+40 (Romania)</option>
                    <option value="+7">+7 (Russia)</option>
                    <option value="+250">+250 (Rwanda)</option>
                    <option value="+290">+290 (Saint Helena)</option>
                    <option value="+1-869">+1-869 (Saint Kitts and Nevis)</option>
                    <option value="+1-758">+1-758 (Saint Lucia)</option>
                    <option value="+508">+508 (Saint Pierre and Miquelon)</option>
                    <option value="+1-784">+1-784 (Saint Vincent and the Grenadines)</option>
                    <option value="+685">+685 (Samoa)</option>
                    <option value="+378">+378 (San Marino)</option>
                    <option value="+239">+239 (Sao Tome and Principe)</option>
                    <option value="+966">+966 (Saudi Arabia)</option>
                    <option value="+221">+221 (Senegal)</option>
                    <option value="+381">+381 (Serbia)</option>
                    <option value="+248">+248 (Seychelles)</option>
                    <option value="+232">+232 (Sierra Leone)</option>
                    <option value="+65">+65 (Singapore)</option>
                    <option value="+1-721">+1-721 (Sint Maarten)</option>
                    <option value="+421">+421 (Slovakia)</option>
                    <option value="+386">+386 (Slovenia)</option>
                    <option value="+677">+677 (Solomon Islands)</option>
                    <option value="+252">+252 (Somalia)</option>
                    <option value="+27">+27 (South Africa)</option>
                    <option value="+34">+34 (Spain)</option>
                    <option value="+94">+94 (Sri Lanka)</option>
                    <option value="+249">+249 (Sudan)</option>
                    <option value="+597">+597 (Suriname)</option>
                    <option value="+268">+268 (Swaziland)</option>
                    <option value="+46">+46 (Sweden)</option>
                    <option value="+41">+41 (Switzerland)</option>
                    <option value="+963">+963 (Syria)</option>
                    <option value="+886">+886 (Taiwan)</option>
                    <option value="+992">+992 (Tajikistan)</option>
                    <option value="+255">+255 (Tanzania)</option>
                    <option value="+66">+66 (Thailand)</option>
                    <option value="+228">+228 (Togo)</option>
                    <option value="+676">+676 (Tonga)</option>
                    <option value="+1-868">+1-868 (Trinidad and Tobago)</option>
                    <option value="+216">+216 (Tunisia)</option>
                    <option value="+90">+90 (Turkey)</option>
                    <option value="+993">+993 (Turkmenistan)</option>
                    <option value="+1-649">+1-649 (Turks and Caicos Islands)</option>
                    <option value="+688">+688 (Tuvalu)</option>
                    <option value="+256">+256 (Uganda)</option>
                    <option value="+380">+380 (Ukraine)</option>
                    <option value="+971">+971 (United Arab Emirates)</option>
                    <option value="+44">+44 (United Kingdom)</option>
                    <option value="+1">+1 (United States)</option>
                    <option value="+598">+598 (Uruguay)</option>
                    <option value="+998">+998 (Uzbekistan)</option>
                    <option value="+678">+678 (Vanuatu)</option>
                    <option value="+379">+379 (Vatican City)</option>
                    <option value="+58">+58 (Venezuela)</option>
                    <option value="+84">+84 (Vietnam)</option>
                    <option value="+681">+681 (Wallis and Futuna)</option>
                    <option value="+967">+967 (Yemen)</option>
                    <option value="+260">+260 (Zambia)</option>
                    <option value="+263">+263 (Zimbabwe)</option>

                    {/* Continue country codes here */}
                  </select>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    aria-label="Mobile Number"
                    className="w-2/3 px-3 py-2 border rounded text-black"
                    placeholder="Your Mobile Number"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="mb-4">
                <label className="sr-only" htmlFor="service">Service Interested In</label>
                <select
                  name="service"
                  id="service"
                  aria-label="Service Interested In"
                  className="w-full px-3 py-2 border rounded text-black"
                  value={formData.service}
                  onChange={handleChange}
                  required
                >
                  <option value="Digital Marketing">Digital Marketing</option>
                  <option value="Paid Ads">Paid Ads</option>
                  <option value="Website Development">Website Development</option>
                  <option value="SEO">SEO</option>
                  <option value="Social Media Marketing">Social Media Marketing</option>
                </select>
              </div>

              <div className="mb-4">
                <label className="sr-only" htmlFor="message">Message</label>
                <textarea
                  name="message"
                  id="message"
                  aria-label="Message"
                  className="w-full px-3 py-2 border rounded text-black"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  required
                />
              </div>

              <button
                type="submit"
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300 flex items-center justify-center"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <div className="animate-spin h-5 w-5 mr-2 border-4 border-t-transparent border-white rounded-full"></div>
                ) : (
                  'Submit'
                )}
              </button>

              {formStatus.error && <p className="text-red-500 mt-4">{formStatus.error}</p>}
            </form>
          </div>
        </div>
      </div>

      <style jsx>{`
        .animate-spin {
          animation: spin 1s linear infinite;
        }
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </HelmetProvider>
  );
};

export default BlogPostPage;
