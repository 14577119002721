import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeroSection8 from "../assets/contactus/HeroSection8";
import Contact from "..//assets/contactus/Contact";
import Testimonials from "..//assets/home/Testimonials";


const HomePage = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Contact Us | RedSprout Digital</title>
          <meta
            name="description"
            content="Contact us to RedSprout Digital for expert digital marketing services including SEO, PPC, social media, web development. We're here to help your business grow."
          />
          <meta
            name="keywords"
            content="contact us, contact redsprout digital, contact for digital marketing solutions, "
          />
          <meta property="og:title" content="Contact Us | RedSprout Digital" />
          <meta property="og:description" content="Contact us to RedSprout Digital for expert digital marketing services including SEO, PPC, social media, web development. We're here to help your business grow." />
          <meta property="og:image" content="https://redsproutdigital.com/Images/logo.webp?v=1" />
          <meta property="og:url" content="https://redsproutdigital.com/contact-us/" />
          <link rel="canonical" href="https://redsproutdigital.com/contact-us/" />
        </Helmet>
        {/* Hero Section */}
        <HeroSection8 />
        <Contact />
        <Testimonials />

      </div>
    </HelmetProvider>
  );
};

export default HomePage;
