import React, { useState, useEffect, useRef } from 'react';
import { db, auth, storage } from '../firebase/Firebase';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, updateDoc, deleteDoc, addDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable, deleteObject } from 'firebase/storage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify'; // Sanitize HTML for React Quill
import Pagination from './pagination'; // Ensure this is the correct path

const AdminDashboard = () => {
  const [posts, setPosts] = useState([]);
  const [editingPostId, setEditingPostId] = useState(null);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [keywords, setKeywords] = useState('');
  const [tags, setTags] = useState('');
  const [postDate, setPostDate] = useState('');
  const [featuredImage, setFeaturedImage] = useState(null);
  const [preview, setPreview] = useState(null); // For image preview
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [view, setView] = useState('create');
  const [newCategory, setNewCategory] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [permalink, setPermalink] = useState('');
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const postsPerPage = 10; // Define posts per page

  const navigate = useNavigate();
  const inactivityTimeoutRef = useRef(null);
  const logoutAfterInactivity = 15 * 60 * 1000; // 15 minutes

  // Inactivity timeout for auto logout
  useEffect(() => {
    const resetInactivityTimeout = () => {
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
      inactivityTimeoutRef.current = setTimeout(() => {
        handleLogout();
      }, logoutAfterInactivity);
    };

    const events = ['mousemove', 'keydown', 'click'];
    events.forEach((event) => window.addEventListener(event, resetInactivityTimeout));
    resetInactivityTimeout(); // Initialize timer on component mount

    return () => {
      events.forEach((event) => window.removeEventListener(event, resetInactivityTimeout));
      clearTimeout(inactivityTimeoutRef.current); // Cleanup on unmount
    };
  }, []);

  // Fetch posts, categories, and users on component mount
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'posts'));
        setPosts(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (err) {
        console.error('Error fetching posts:', err);
      }
    };

    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'categories'));
        setCategoryList(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (err) {
        console.error('Error fetching categories:', err);
      }
    };

    fetchPosts();
    fetchCategories();
  }, []);

  // Handle Image Upload
  const handleImageUpload = async (file) => {
    if (!file) return null;

    const storageRef = ref(storage, `avatars/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        () => {},
        (error) => {
          console.error('Error uploading image:', error);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          } catch (error) {
            console.error('Error getting download URL:', error);
            reject(error);
          }
        }
      );
    });
  };

  // Handle Post Editing
  const handleEditPost = (post) => {
    setEditingPostId(post.id);
    setTitle(post.title);
    setBody(post.body);
    setSelectedCategory(post.categories[0]);
    setMetaTitle(post.metaTitle);
    setMetaDescription(post.metaDescription);
    setKeywords(post.keywords.join(', '));
    setTags(post.tags.join(', '));

    // Only set the date if it's a valid Firestore Timestamp or Date
    if (post.postDate && post.postDate.seconds) {
      setPostDate(new Date(post.postDate.seconds * 1000).toISOString().split('T')[0]);
    } else {
      setPostDate('');
    }

    setFeaturedImage(null);
    setPermalink(post.permalink.replace('/blog/', ''));
    setView('create');
  };

  // Handle Post Deletion
  const handleDeletePost = async (postId, imageURL) => {
    try {
      await deleteDoc(doc(db, 'posts', postId));
      if (imageURL) {
        const imageRef = ref(storage, imageURL);
        await deleteObject(imageRef);
      }
      setPosts(posts.filter(post => post.id !== postId));
      setSuccessMessage('Post deleted successfully!');
    } catch (error) {
      console.error('Error deleting post:', error);
      setError('Failed to delete post.');
    }
  };

  // Handle Adding New Category
  const handleAddCategory = async () => {
    if (!newCategory) return;

    try {
      const docRef = await addDoc(collection(db, 'categories'), { name: newCategory });
      setCategoryList([...categoryList, { id: docRef.id, name: newCategory }]);
      setNewCategory('');
      setSuccessMessage('Category added successfully!');
    } catch (error) {
      console.error('Error adding category:', error);
      setError('Failed to add category.');
    }
  };

  // Handle Post Publishing
  const handlePublish = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccessMessage('');

    if (!title.trim() || !body.trim() || !selectedCategory || !permalink.trim()) {
      setError('Please fill all the required fields.');
      setLoading(false);
      return;
    }

    try {
      if (!auth.currentUser) {
        throw new Error('User is not authenticated');
      }

      let imageURL = null;
      if (featuredImage) {
        imageURL = await handleImageUpload(featuredImage);
      }

      const sanitizedBody = DOMPurify.sanitize(body); // Sanitize the body content
      const postData = {
        title,
        body: sanitizedBody,
        categories: [selectedCategory],
        metaTitle,
        metaDescription,
        keywords: keywords.split(',').map((key) => key.trim()),
        tags: tags.split(',').map((tag) => tag.trim()),
        postDate: postDate ? new Date(postDate) : new Date(),
        author: auth.currentUser.email,
        featuredImage: imageURL || posts.find(post => post.id === editingPostId)?.featuredImage,
        permalink: `/blog/${permalink}`,
      };

      if (editingPostId) {
        await updateDoc(doc(db, 'posts', editingPostId), postData);
        setPosts(posts.map(post => (post.id === editingPostId ? { id: post.id, ...postData } : post)));
        setSuccessMessage('Post updated successfully!');
        setEditingPostId(null);
      } else {
        const docRef = await addDoc(collection(db, 'posts'), postData);
        setPosts([...posts, { id: docRef.id, ...postData }]);
        setSuccessMessage('Post published successfully!');
      }

      // Clear form fields
      setTitle('');
      setBody('');
      setSelectedCategory('');
      setMetaTitle('');
      setMetaDescription('');
      setKeywords('');
      setTags('');
      setPostDate('');
      setFeaturedImage(null);
      setPermalink('');
    } catch (error) {
      console.error('Error publishing post:', error);
      setError('Failed to publish post. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handle Logout
  const handleLogout = () => {
    auth.signOut();
    navigate('/admin'); // Redirect to the login page after logout
  };

  // Editor modules and formats
  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const formats = [
    'header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent', 'link', 'image',
  ];

  // Pagination logic
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <div className="flex min-h-screen bg-gradient-to-tl from-red-100 via-white to-red-100">
      {/* Left Pane - Fixed */}
      <div className="w-64 bg-black text-white shadow-lg p-4 fixed h-full rounded-r-lg">
        <div className="mb-8">
          <div className="flex items-center space-x-4 mb-4">
            <span>{auth.currentUser?.displayName || "Admin"}</span>
          </div>
        </div>
        <h2 className="text-2xl font-bold mb-8">Admin Dashboard</h2>
        <nav className="flex flex-col space-y-4">
          <button
            className="text-left hover:text-blue-400 transition duration-300"
            onClick={() => setView('create')}
          >
            Create Blog Post
          </button>
          <button
            className="text-left hover:text-blue-400 transition duration-300"
            onClick={() => setView('list')}
          >
            List of Blog Posts
          </button>
          <button
            className="text-left hover:text-blue-400 transition duration-300"
            onClick={() => setView('categories')}
          >
            Manage Categories
          </button>
        </nav>
        <div className="mt-auto">
          <button
            onClick={handleLogout}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300 mt-8 mx-4"
          >
            Logout
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-8 ml-64 mt-10 overflow-y-auto bg-gradient-to-br from-red-100 via-white to-red-100">
        {view === 'create' && (
          <form onSubmit={handlePublish} className="bg-white p-6 rounded shadow-md mb-8">
            <h3 className="text-xl font-bold mb-4">{editingPostId ? 'Edit Post' : 'Create New Post'}</h3>

            {loading && (
              <div className="fixed inset-0 flex items-center justify-center bg-gray-100 bg-opacity-75 z-50">
                <div className="flex items-center">
                  <div
                    className="inline-block w-8 h-8 border-4 border-gray-300 border-t-4 border-t-blue-600 rounded-full animate-spin"
                    style={{ borderTopColor: "#3498db" }}
                  ></div>
                  <span className="ml-4 text-lg">Processing...</span>
                </div>
              </div>
            )}
            {error && <p className="text-red-500">{error}</p>}
            {successMessage && <p className="text-green-500">{successMessage}</p>}

            <div className="mb-4">
              <label className="block text-gray-700">Title:</label>
              <input
                type="text"
                className="w-full px-3 py-2 border rounded"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Body:</label>
              <ReactQuill
                theme="snow"
                value={body}
                onChange={setBody}
                className="bg-white"
                required
                modules={modules}
                formats={formats}
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Featured Image:</label>
              <input
                type="file"
                className="w-full px-3 py-2 border rounded"
                onChange={(e) => {
                  setFeaturedImage(e.target.files[0]);
                  setPreview(URL.createObjectURL(e.target.files[0]));
                }}
              />
              {preview && <img src={preview} alt="Preview" className="w-full max-h-64 object-cover mt-4" />}
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Category:</label>
              <select
                className="w-full px-3 py-2 border rounded"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                required
              >
                <option value="" disabled>Select a category</option>
                {categoryList.map((category) => (
                  <option key={category.id} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Permalink Field */}
            <div className="mb-4">
              <label className="block text-gray-700">Permalink:</label>
              <div className="flex items-center">
                <span className="text-gray-500">/blog/</span>
                <input
                  type="text"
                  className="w-full px-3 py-2 border rounded"
                  value={permalink}
                  onChange={(e) => setPermalink(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Post Date:</label>
              <input
                type="date"
                className="w-full px-3 py-2 border rounded"
                value={postDate}
                onChange={(e) => setPostDate(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Tags (comma separated):</label>
              <input
                type="text"
                className="w-full px-3 py-2 border rounded"
                value={tags}
                onChange={(e) => setTags(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Meta Title:</label>
              <input
                type="text"
                className="w-full px-3 py-2 border rounded"
                value={metaTitle}
                onChange={(e) => setMetaTitle(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Meta Description:</label>
              <textarea
                className="w-full px-3 py-2 border rounded h-20"
                value={metaDescription}
                onChange={(e) => setMetaDescription(e.target.value)}
              ></textarea>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Keywords (comma separated):</label>
              <input
                type="text"
                className="w-full px-3 py-2 border rounded"
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
              />
            </div>

            <button
              type="submit"
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-black transition duration-300"
            >
              {editingPostId ? 'Update Post' : 'Publish Post'}
            </button>
          </form>
        )}

        {view === 'list' && (
          <div className="bg-white p-6 rounded shadow-md">
            <h3 className="text-xl font-bold mb-4">List of Blog Posts</h3>
            <table className="w-full text-left">
              <thead>
                <tr>
                  <th className="border-b py-2">Title</th>
                  <th className="border-b py-2">Date</th>
                  <th className="border-b py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts.map(post => (
                  <tr key={post.id}>
                    <td className="border-b py-2">{post.title}</td>
                    <td className="border-b py-2">
                      {post.postDate?.seconds
                        ? new Date(post.postDate.seconds * 1000).toLocaleDateString()
                        : "No Date Available"}
                    </td>
                    <td className="border-b py-2">
                      <button
                        onClick={() => handleEditPost(post)}
                        className="text-blue-600 hover:underline mr-4"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDeletePost(post.id, post.featuredImage)}
                        className="text-red-600 hover:underline"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination Component */}
            <Pagination
              totalPosts={posts.length}
              postsPerPage={postsPerPage}
              currentPage={currentPage}
              paginate={setCurrentPage}
            />
          </div>
        )}

        {view === 'categories' && (
          <div className="bg-white p-6 rounded shadow-md">
            <h3 className="text-xl font-bold mb-4">Manage Categories</h3>
            <div className="mb-4">
              <label className="block text-gray-700">New Category:</label>
              <input
                type="text"
                className="w-full px-3 py-2 border rounded"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
              />
              <button
                onClick={handleAddCategory}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-black transition duration-300 mt-2"
              >
                Add Category
              </button>
            </div>
            <h4 className="text-lg font-bold mb-2">Existing Categories:</h4>
            <ul>
              {categoryList.map((category) => (
                <li key={category.id} className="mb-2">
                  {category.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
