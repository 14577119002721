import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeroSectionUSA from "../assets/digitalmarketingagency/HeroSectionUSA";
import ContentUSA from "..//assets/digitalmarketingagency/ContentUSA";
import BottomCTA from "..//assets/home/BottomCTA";

const DigitalMarketingAgencyinUSA = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Top Digital Marketing Agency in USA | RedSprout Digital</title>
          <meta
            name="description"
            content="Looking for a top digital marketing agency in USA? RedSprout Digital offers expert SEO, web development, and paid ads services to grow your business fast."
          />
          <meta
            name="keywords"
            content="digital marketing agency in usa, best website development company in usa, Top web development companies, seo agency usa"
          />
          <meta property="og:title" content="Top Digital Marketing Agency in USA | RedSprout Digital" />
          <meta property="og:description" content="Looking for a top digital marketing agency in USA? RedSprout Digital offers expert SEO, web development, and paid ads services to grow your business fast." />
          <meta property="og:image" content="https://redsproutdigital.com/Images/logo.webp?v=1" />
          <meta property="og:url" content="https://redsproutdigital.com/digital-marketing-agency-in-usa/" />
          <link rel="canonical" href="https://redsproutdigital.com/digital-marketing-agency-in-usa/" />
        </Helmet>
        {/* Hero Section */}
        <HeroSectionUSA />
        <ContentUSA />
        <BottomCTA />



      </div>
    </HelmetProvider>
  );
};

export default DigitalMarketingAgencyinUSA;
