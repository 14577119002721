import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
const PrivacyPolicy = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Refund Policy | RedSprout Digital</title>
          <meta
            name="description"
            content="Explore RedSprout Digital's refund policy, ensuring customer satisfaction, data security, and confidentiality with clear guidelines for refunds and cancellations."
          />
          <meta
            name="keywords"
            content="privacy policy"
          />
          <meta property="og:title" content="Refund Policy | RedSprout Digital" />
          <meta property="og:description" content="Explore RedSprout Digital's refund policy, ensuring customer satisfaction, data security, and confidentiality with clear guidelines for refunds and cancellations." />
          <meta property="og:image" content="https://redsproutdigital.com/Images/logo.webp?v=1" />
          <meta property="og:url" content="https://redsproutdigital.com/refund-policy/" />
          <link rel="canonical" href="https://redsproutdigital.com/refund-policy/" />
        </Helmet>
        <div className="relative bg-gradient-to-tl from-red-100 via-white to-red-100 py-16 overflow-hidden">
          <div className="container mx-auto px-4">
            <h1 className="text-4xl font-extrabold mb-4 text-center">Refund Policy</h1>
            <h2 className="text-2xl font-bold mb-2">Refund Policy</h2>
            <p className="mb-4 text-gray-600 text-left">Last updated: December 07, 2023</p>

            <p className="mb-4">
              At RedSprout Digital, we are committed to providing value and satisfaction to our clients. However, for transparency, we have outlined our refund policy below.
            </p>

            <h2 className="text-2xl font-bold mb-2">General Policy</h2>
            <h3 className="text-xl font-semibold mb-2"></h3>
            <p className="mb-4">
              By signing up, you agree to our refund policy, acknowledging that all services are non-refundable.</p>

            <p className="mb-4">Due to the nature of personalized digital services and manual labor, refunds are not offered. We operate as a low-cost creative services subscription that can be canceled on a month-to-month basis.</p>

            <p className="mb-4">While we strive to meet your expectations, this is not a "pay if you like it" service. You pay for creative deliverables based on your brief, feedback, and revisions. We do not guarantee satisfaction, marketing results, growth, or engagement but will happily incorporate your revisions.</p>
            <p className="mb-4">Our services are subscription-based, not on-demand, meaning that once purchased, team members are reserved for your project. Therefore, failure to utilize the service does not warrant a full or partial refund.
            </p>


            <h2 className="text-2xl font-bold mb-2">Deliverables</h2>

            <p className="mb-4">
              If you are dissatisfied with the deliverables, we do not offer refunds but are open to making necessary revisions. Once deliverables are approved, no further revisions are allowed.
            </p>
            <h2 className="text-2xl font-bold mb-2">Cancellation</h2>

            <p className="mb-4">
              You may cancel your subscription at any time through the Client Portal by selecting 'manage subscription' within your order. No prorated refunds will be provided for early cancellations. If you are charged because you forgot to cancel, we do not offer refunds, as work will have already begun on the next round of content.
            </p>

            <h2 className="text-2xl font-bold mb-2">Renewal</h2>

            <p className="mb-4">
              All RedSprout Digital services are recurring subscriptions, meaning you will be automatically charged each month. Once a subscription renews, it cannot be refunded under any circumstance. You will be required to accept all content and services provided for that period with appropriate and fair client behavior.
            </p>
            <h2 className="text-2xl font-bold mb-2">Results</h2>

            <p className="mb-4">
              Content is only one part of your business's overall performance. We do not guarantee results, as our service is a creative one with final approval in your hands. You are paying for creative deliverables that our team develops based on your brief and feedback.
            </p>
            <h2 className="text-2xl font-bold mb-2">Onboarding & Sign-Up  </h2>

            <p className="mb-4">
              Failure to complete the onboarding questionnaire does not qualify for a refund, but we can convert your payment into credits for other services.
            </p>
            <p className="mb-4">Refund requests made immediately after completing the questionnaire do not qualify, as production on your service starts right away. Depending on the timing, we may convert your payment into credits for other services if needed.</p>
            <h2 className="text-2xl font-bold mb-2">Technical Issues </h2>

            <p className="mb-4">
              You are paying us for the creation of social media content. Posting and scheduling are free, added-value services, so no refunds will be issued for related issues. However, we will do our best to resolve any technical issues.
            </p>
            <p className="mb-4">
              Any issues regarding the connection of your social media accounts to our scheduling platform do not qualify for refunds. If these issues cannot be resolved, you may download the content and post it manually. We cannot post approved content until at least one social media account is connected to our platform.
            </p>
            <p className="mb-4">
              If your social media account is disabled, or if content is removed by the platform for reasons such as sensitive industries (weight loss, cannabis, etc.), we will not be held accountable, and refunds will not be issued. You approve all content before it is published, and responsibility ultimately rests with you.
            </p>
            <p className="mb-4">
              You are paying for the creative deliverables (content creation) and not the actual scheduling/posting. Therefore, if we cannot schedule or post content for any reason, this does not warrant a refund. It is your responsibility to review your social media accounts, ensure the connection works, and confirm that posts are published correctly. If posts fail to go out due to account disconnections, we will not issue refunds but can reschedule posts or provide the option for you to post them manually once the issue is resolved.
            </p>

            <h2 className="text-2xl font-bold mb-2">Mistakes</h2>

            <p className="mb-4">
              While we adhere to strict quality assurance procedures, occasional mistakes may occur. It is your responsibility to review and approve the content carefully, as we do not issue refunds for errors such as typos, incorrect information, or timing issues with posts.
            </p>
            <ul>
              <li>We do not refund for typos or incorrect information in deliverables. </li>
              <li>We do not refund for posts going live on an unintended date.</li>
              <li>We do not refund for missed posting deadlines, but we can create new content to replace the outdated post.           </li>
            </ul>

            <h2 className="text-2xl font-bold mb-2">Delays</h2>

            <p className="mb-4">
              Delays in communication can cause issues, but no refunds will be provided for delays caused by the client.
            </p>
            <p className="mb-4">Any delays caused by RedSprout Digital will not warrant refunds but may result in an adjusted billing date to compensate for lost time. Delays caused by the client will not result in adjustments to billing dates or refunds.</p>
            <p className="mb-4">If 25 days pass after signup without the submission of your questionnaire, we will proceed to create deliverables based on our research, and no revisions will be permitted unless otherwise specified.</p>
            <p className="mb-4"> If feedback or approvals are not provided before the next billing cycle, we will proceed with creating the next month’s content. If more than 30 days pass after we send posts for approval, no revision requests will be allowed for that batch.</p>
            <h2 className="text-2xl font-bold mb-2">Resellers</h2>

            <p className="mb-4">
              If your client does not pay you, we will not issue a refund for the services we have rendered.
            </p>
            <h2 className="text-2xl font-bold mb-2">Credit Card Disputes   </h2>

            <p className="mb-4">
              You agree not to dispute any payments for reasons covered in our terms and refund policy.
            </p>
            <p className="mb-4">In the event of a credit card dispute, all deliverables (and any published content) created during your subscription will be automatically deleted as a system default. Once this occurs, we cannot recover the content.</p>
            <p className="mb-4">Disputes will halt all credits, communication, and account work. If you win a dispute, we will send you an invoice for the disputed amount, including any fees incurred, with payment due upon receipt. If not paid, the account will be turned over to collections and reported to credit bureaus.</p>

            <h2 className="text-2xl font-bold mb-2">Blackmail & Threats </h2>

            <p className="mb-4">
              We do not tolerate attempts to bypass our refund policy through blackmail, including threats of disputes or negative reviews in exchange for refunds when services have been delivered.
            </p>
            <p className="mb-4">Review platforms often have policies against using reviews as blackmail and may remove such reviews or ban users who violate these rules. Escalation may lead to legal action, as blackmail and extortion are criminal offenses. We may also seek civil remedies for any damaging negative reviews left in relation to refund requests.</p>
            <p className="mb-4">We appreciate your understanding and adherence to these policies, designed to maintain a fair and transparent relationship while ensuring high-quality service.</p>



            <h2 className="text-2xl font-bold mb-2">Contact Us</h2>
            <p className="mb-4">
              If you have any questions about this Refund Policy, You can contact us:
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>By email: <a href="mailto:info@redsproutdigital.com" className="text-blue-600 hover:underline">info@redsproutdigital.com</a></li>
            </ul>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default PrivacyPolicy;
