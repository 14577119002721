import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeroSection2 from "../assets/whoweare/HeroSection2";
import LogoCarousel from "..//assets/home/LogoCarousel";
import Testimonials from "..//assets/home/Testimonials";
import CTASection from "..//assets/home/CTAsSection";


const HomePage = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>We're RedSprout Digital | Expert Digital Marketing Solutions</title>
          <meta
            name="description"
            content="We’re RedSprout Digital, offering expert digital marketing solutions including SEO, social media marketing,  customized strategies to grow your business online."
          />
          <meta
            name="keywords"
            content="we're redsprout digital, about redsprout digital"
          />
          <meta property="og:title" content="We're RedSprout Digital | Expert Digital Marketing Solutions" />
          <meta property="og:description" content="We’re RedSprout Digital, offering expert digital marketing solutions including SEO, social media marketing,  customized strategies to grow your business online." />
          <meta property="og:image" content="https://redsproutdigital.com/Images/logo.webp?v=1" />
          <meta property="og:url" content="https://redsproutdigital.com/who-we-are/" />
          <link rel="canonical" href="https://redsproutdigital.com/who-we-are/" />
        </Helmet>

        {/* Hero Section */}
        <HeroSection2 />
        <CTASection />
        <Testimonials />
        <LogoCarousel />

      </div>
    </HelmetProvider>
  );
};

export default HomePage;